<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="true" color="color3" class="ma-0"></v-progress-linear>
    <div v-else style="height: 7px"></div>
    <v-container class="pt-0">
      <v-row dense>
        <v-col class="text-right" cols="12" >
          <v-btn color="color3" text class="xsmall" @click.stop="avpaFormat = !avpaFormat">Avp America Export</v-btn>
        </v-col>
      </v-row>
        <v-expand-transition>
          <div v-if="avpaFormat">
            <v-row dense>
              <v-col cols="12" v-if="playerDivisions.length > 1">
                <v-select
                  :items="playerDivisions"
                  item-text="name"
                  item-value="id"
                  v-model="selectedDivisionIds"
                  label="Divisions"
                  hint="Choose divisions to include"
                  persistent-hint
                  multiple
                  chips
                  color="color3"
                  item-color="color3"
                  ref="select1"
                >
                  <v-list-item
                    slot="prepend-item"
                    ripple
                    @click="toggleDivision"
                    :color="icon.color"
                  >
                    <v-list-item-action>
                      <v-icon :color="icon.color">{{icon.name}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item>
                  <v-divider
                    slot="prepend-item"
                    class="mt-2"
                  ></v-divider>
                </v-select>
              </v-col>
              <v-col cols="12" class="title">
                Map Divisions To Avp America
                <div class="subheading">
                  Choose the division that you selected on the Avp America site
                </div>
              </v-col>
            </v-row>
            <v-row dense v-for="d in selectedDivisions" :key="d.id">
              <v-col cols="4" class="title d-flex align-center">
                <span>{{d.name}}</span><v-icon>fas fa-angle-double-right</v-icon>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  :items="avpaDivisions"
                  v-model="d.avpaMap"
                  label="Avp America Division"
                  persistent-hint
                  hint="Select the Avp America Division you used when you created the event on their site"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="!avpaFormat">
            <v-row dense>
              <v-col cols="12">
                <div class="subheading">Rows</div>
                <v-radio-group v-model="teams" row hide-details>
                  <v-radio label="1 team per row" :value="true" color="color3"></v-radio>
                  <v-radio label="1 player per row" :value="false" color="color3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="playerDivisions.length > 1">
                <v-select
                  :items="playerDivisions"
                  item-text="name"
                  item-value="id"
                  v-model="selectedDivisionIds"
                  label="Divisions"
                  hint="Choose divisions to include"
                  persistent-hint
                  multiple
                  chips
                  color="color3"
                  item-color="color3"
                  ref="select2"
                >
                  <v-list-item
                    slot="prepend-item"
                    ripple
                    @click="toggleDivision"
                    :color="icon.color"
                  >
                    <v-list-item-action>
                      <v-icon :color="icon.color">{{icon.name}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item>
                  <v-divider
                    slot="prepend-item"
                    class="mt-2"
                  ></v-divider>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-checkbox color="color3" label="Include waitlist teams" v-model="waitlisted" hide-details class="mt-0 pt-0"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox color="color3" label="Include withdrawn teams" v-model="deleted" hide-details class="mt-0 pt-0"></v-checkbox>
              </v-col>
              <v-col cols="12" v-if="false">
                <v-checkbox color="color3" label="USAV Export" v-model="usav" hide-details class="mt-0 pt-0"></v-checkbox>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row dense class="mt-3" v-if="!usav">
                <v-col cols="12">
                  <div class="subheading">Columns</div>
                  <v-checkbox color="color3" label="Registration Fields" v-model="showRegFields" hide-details class="mt-0"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox color="color3" label="Seed" v-model="seed" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox color="color3" label="Finish" v-model="finish" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox color="color3" label="AAU Points" v-model="aau" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox color="color3" label="AVP America Points" v-model="avpa" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox color="color3" label="Results By Round" v-model="stats" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
              </v-row>
            </v-expand-transition>
          </div>
        </v-expand-transition>
      <v-row dense justify="center">
        <v-btn
          color="color3 color3Text--text"
          :disabled="selectedDivisions.length === 0"
          @click.stop="onDownloadClick"
          :loading="loadingRegFields"
        >
          <v-icon class="mr-4">fas fa-cloud-download-alt</v-icon>
          Download
        </v-btn>
      </v-row>
      <v-row dense justify="center" v-if="coachData" class="pt-3">
        <v-btn
          color="color3 color3Text--text"
          @click.stop="onDownloadClick(true)"
          :loading="loadingRegFields"
          :disabled="loadingRegFields"
        >
          <v-icon class="mr-4">fas fa-cloud-download-alt</v-icon>
          Download Coaches
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import flatten from '../../helpers/ArrayFlatten'
import AvpaDivisions from '@/classes/AvpaDivisions'
import { firstBy } from 'thenby'
import { formatArray } from '@/Filters'

export default {
  props: ['active'],
  data () {
    return {
      teams: true,
      deleted: false,
      showRegFields: true,
      seed: false,
      finish: false,
      aau: false,
      avpa: false,
      avp: false,
      selectedDivisionIds: [],
      loadingRegFields: false,
      regFields: null,
      avpaFormat: false,
      stats: false,
      usav: false,
      waitlisted: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getTeam', 'theme']),
    showMemberId () {
      return this.theme.membership || ['aau', 'avp', 'usav', 'p1440', 'gnbv', 'bvne'].includes(this.theme.username)
    },
    downloadDisabled () {
      return this.avpaFormat ? this.fullyMapped : this.selectedDivisions.length
    },
    playerDivisions () {
      return this.tournament.publicDivisions.filter(f => !f.isCoaches)
    },
    coachDivisions () {
      return this.tournament.publicDivisions.filter(f => f.isCoaches)
    },
    fullyMapped () {
      return this.selectedDivisions.length && !this.selectedDivisions.filter(f => !f.avpaMap).length
    },
    allDivisions () {
      return this.selectedDivisionIds.length === this.playerDivisions.length
    },
    someDivisions () {
      return this.selectedDivisionIds.length > 0 && !this.allDivisions
    },
    icon () {
      if (this.allDivisions) return { name: 'fas fa-check-square', color: 'color3' }
      if (this.someDivisions) return { name: 'fas fa-minus-square', color: 'color3' }
      return { name: 'far fa-square', color: '' }
    },
    loading () {
      return this.loadingRegFields
    },
    selectedDivisions () {
      if (this.playerDivisions.length === 1) return this.playerDivisions

      return this.playerDivisions.filter(d =>
        this.selectedDivisionIds.includes(d.id)
      )
    },
    selectedTeams () {
      return flatten(this.selectedDivisions.map(division => {
        return division.teams.map(team => {
          return Object.assign({}, team, {
            division: division.name || division.division.name,
            _d: division,
            waitlist: team.waitlist,
            gender: division.gender.name,
            avpaMap: division.avpaMap,
            pCount: team.players.length
          })
        })
      }))
    },
    teamData () {
      let teams = this.deleted ? this.selectedTeams : this.selectedTeams.filter(f => !f.isDeleted)
      if (!this.waitlisted) {
        teams = teams.filter(f => !f.waitlist)
      }
      const hasWL = !!teams.find(f => f.waitlist)
      const result = teams.map(team => {
        const obj = {
          'Team Name': team.name,
          Division: team.division,
          Gender: team.gender
        }
        if (hasWL) {
          obj.Waitlisted = team.waitlist
        }
        if (this.deleted) obj.Status = team.isDeleted ? 'WITHDREW' : 'REGISTERED'
        if (this.seed) obj.Seed = team.seed
        if (this.finish) {
          obj.Finish = team.finish < 999 ? team.finish : null
          // obj['Points Earned'] = team.finishPoints
          if (team._d.bracketRounds.length > 1) {
            const br = team._d.getBracketRound(team.id)
            if (br) obj.Bracket = br.name
          }
          obj['Overall Finish'] = team.iFinish
        }

        if (this.aau) obj['AAU Points'] = team.aauSeedingPoints
        if (this.avpa) obj['AVP America Points'] = team.avpAmericaSeedingPoints
        if (this.avp) obj['AVP Points'] = team.avpSeedingPoints
        if (this.showRegFields) {
          var players = [...team.players].sort(firstBy('id'))
          players.forEach((player, i) => {
            obj[`Player ${i + 1} VBL Id`] = player.playerProfileId

            let fields = this.regFields.filter(f => f.i === player.playerProfileId)
            if (fields.length === 1) {
              fields = fields[0]
            } else {
              console.log('multifield')
              fields = fields.find(f => f.tId === team.id)
            }
            if (fields) {
              fields.fields.forEach(field => {
                const p = `Player ${i + 1} `
                const label = field.label.startsWith(p) ? field.label : `Player ${i + 1} ${field.label}`
                obj[label] = field.value
              })
            }
            if (this.showMemberId) {
              obj[`Player ${i + 1} ${this.theme.username.toUpperCase()} Number`] = player.getMemberId(this.theme.username)
            }
            if (this.avpa) {
              obj[`Player ${i + 1} USAVP Points`] = this.getUsavpPoints(team.id, player.id)
            }
          })
        }
        if (this.stats) {
          const division = this.playerDivisions.find(f => f.activeTeams.find(t => t.id === team.id))
          const rounds = division && division.days.filter(f => f.hasTeam(team.id))
          obj.Rounds_Played = rounds.length
          if (rounds) {
            rounds.forEach(r => {
              const t = r.allTeams.find(f => f.teamId === team.id)
              if (t.poolId) {
                const p = r.pools.find(f => f.id === t.poolId)
                const f = p && p.rankedTeams.find(f => f.teamId === t.teamId)
                if (f && f.summary) {
                  obj[`Round ${r.number}`] = `Pool ${p.name}`
                  obj[`Round ${r.number} Finish`] = f.summary.finish
                  obj[`Round ${r.number} Wins`] = f.summary.matchWins
                  obj[`Round ${r.number} Losses`] = f.summary.matchLosses
                  obj[`Round ${r.number} Points For`] = f.summary.pointsFor
                  obj[`Round ${r.number} Points Against`] = f.summary.pointsAgainst
                }
              } else {
                const b = r.brackets[0]
                if (b) {
                  obj[`Round ${r.number}`] = b.name
                  obj[`Round ${r.number} Seed`] = t ? t.seed : 'NA'
                  obj[`Round ${r.number} Finish`] = t ? t.finish : 'NA'
                  obj[`Round ${r.number} Wins`] = t ? b.allMatches.filter(f => f.winningTeam && f.winningTeam.teamId === t.teamId).length : 'NA'
                  obj[`Round ${r.number} Losses`] = t ? b.allMatches.filter(f => f.losingTeam && f.losingTeam.teamId === t.teamId).length : 'NA'
                }
              }
            })
          }
        }

        if (this.teamFieldLabels.length > 0) {
          this.teamFieldLabels.forEach(label => {
            obj[label] = null
          })
        }

        const teamFields = this.regFields.filter(f => f.team && f.teamId === team.id)
        teamFields.forEach(tf => {
          tf.fields.forEach(tff => {
            obj[tff.label] = tff.value
          })
        })
        obj.Id = team.id
        return obj
      })
      result.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
      return result
    },
    teamFieldLabels () {
      const a = this.regFields.filter(f => f.team)
      if (a.length === 0) return []
      const b = flatten(a.map(m => m.fields))
      return [...new Set(b.map(m => m.label))]
    },
    playerData () {
      const teams = this.deleted ? this.selectedTeams : this.selectedTeams.filter(f => !f.isDeleted)
      const result = flatten(
        teams.map(team => {
          return team.players.map(player => {
            const obj = {
              'Team Name': team.name,
              Name: player.name,
              Division: team.division,
              Gender: team.gender
            }
            if (this.showRegFields) {
              const fields = this.regFields.find(f => f.i === player.playerProfileId)
              obj['VBL Id'] = player.playerProfileId
              if (fields) {
                fields.fields.forEach(field => {
                  obj[`${field.label}`] = field.value
                })
              }
            }
            if (this.deleted) obj.Status = team.isDeleted ? 'WITHDREW' : 'REGISTERED'
            if (this.seed) obj.Seed = team.seed
            if (this.finish) {
              obj.Finish = player.finish < 999 ? player.finish : null
              // obj['Points Earned'] = player.finishPoints
            }
            if (this.aau) obj['AAU Points'] = player.aauSeedingPoints
            if (this.avp) obj['AVP Points'] = player.avpSeedingPoints
            obj.Id = player.id
            return obj
          })
        })
      )
      result.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
      return result
    },
    usavData () {
      const teams = this.selectedTeams.filter(f => !f.isDeleted)
      return teams.map(team => {
        const obj = {
          Division: team.division,
          Place: team.iFinish,
          'Team Name': team.name,
          'Bid Received': ''
        }
        team.players.forEach((player, i) => {
          obj[`Member Number ${i + 1}`] = player.usavNumber || this.getFieldValue(player.playerProfileId, 'usavNumber')
          obj[`First Name ${i + 1}`] = player.firstName || this.getFieldValue(player.playerProfileId, 'firstName')
          obj[`Last Name ${i + 1}`] = player.lastName || this.getFieldValue(player.playerProfileId, 'lastName')
          obj[`Full Name ${i + 1}`] = player.fullname || this.getFieldValue(player.playerProfileId, 'fullname')
          obj[`City ${i + 1}`] = player.city || this.getFieldValue(player.playerProfileId, 'city')
          obj[`State ${i + 1}`] = player.state || this.getFieldValue(player.playerProfileId, 'state')
          obj[`Date of Birth ${i + 1}`] = player.dob || this.getFieldValue(player.playerProfileId, 'dob')
          obj[`Email ${i + 1}`] = player.email || this.getFieldValue(player.playerProfileId, 'email')
        })
        return obj
      }).sort(firstBy('division').thenBy('finish'))
    },
    avpaData () {
      const teams = this.selectedTeams.filter(f => !f.isDeleted)
      return teams.map(team => {
        const obj = {
          division: team.avpaMap,
          name: team.name,
          finish: team.iFinish
        }
        team.players.forEach((player, i) => {
          obj[`AVPA${i}`] = player.avpaNumber
        })
        return obj
      }).sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
    },
    avpaDivisions () {
      return AvpaDivisions
    },
    coachData () {
      if (!this.coachDivisions.length) return null
      const result = flatten(
        this.coachDivisions.map(d => {
          return d.teams.map(team => {
            const obj = {
              Id: team.id,
              Name: team.name
            }
            if (d.coachProps && d.coachProps.team) {
              obj.Club = team.club
              const teams = team.registrationData && team.registrationData.extras && team.registrationData.extras.find(f => f.name === 'Teams')
              const teamList = teams ? JSON.parse(teams.value) : []
              const teamList2 = teamList.map(m => this.tournament.teamsWithDivision.find(f => f.id === m.id))
              obj.Divisions = formatArray(teamList2.map(m => m.division))
              obj.Teams = formatArray(teamList2.map(m => m.team))
            }
            return obj
          })
        })
      )
      result.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
      return result
    }
  },
  methods: {
    getUsavpPoints (tId, pId) {
      const team = this.tournament.teams.find(f => f.id === tId)
      const player = team.players.find(f => f.id === pId)
      return player.getSeedPoints(33)
    },
    getFieldValue (ppId, field) {
      const fields = this.regFields.find(f => f.i === ppId)
      if (fields) {
        const a = fields.fields.find(f => {
          console.log(f)
          return f.field.toLowerCase() === field.toLowerCase()
        })
        if (a) return a.value
      }
      return null
    },
    onDownloadClick (coaches) {
      console.log('here')
      var fileName = `${this.tournament.name.replace(/ /g, '_')} ${this.avpaFormat ? 'Avp America Import' : this.teams ? 'Teams' : 'Players'}.csv`
      var data = coaches === true ? this.coachData : this.avpaFormat ? this.avpaData : this.usav ? this.usavData : this.teams ? this.teamData : this.playerData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    toggleDivision () {
      this.$nextTick(() => {
        if (this.allDivisions) {
          this.selectedDivisionIds = []
        } else {
          this.selectedDivisionIds = this.playerDivisions.map(d => d.id)
        }
        if (this.$refs.select1) this.$refs.select1.blur()
        if (this.$refs.select2) this.$refs.select2.blur()
      })
    },
    loadRegFields () {
      if (this.loadingRegFields) return
      this.loadingRegFields = true
      this.$VBL.get.registrationFields(this.tournament.id)
        .then(r => {
          this.regFields = r.data
          if (this.tournament.props.includes('NO_XS')) {
            this.noXS()
          }
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loadingRegFields = false })
    },
    noXS () {
      this.regFields.forEach(rf => {
        const xs = rf.fields && rf.fields.filter(f => f.field === 'ShirtSize' && f.value === 'XS')
        if (xs && xs.length > 0) {
          xs.forEach(x => { x.value = 'S' })
        }
      })
    }
  },
  watch: {
    showRegFields: function (val) {
      if (val) {
        this.loadRegFields()
      }
    },
    active: function (val) {
      if (val && !this.regFields) {
        this.loadRegFields()
      }
    }
  },
  mounted () {
    this.loadRegFields()
  }
}
</script>

<style>

</style>
